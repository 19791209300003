
export const proceedsTableConf = [
	{
		label: '默认账户',
		prop: '1'
	},
	{
		label: '账户名称',
		prop: 'username'
	},
	{
		label: '账户',
		prop: 'account'
	},
	{
		label: '账户类型',
		prop: 'typeInfo'
	},
	{
		label: '开户行',
		prop: 'bank_address'
	},
	{
		label: '操作',
		prop: 'operation'
	}
]